$(document).ready(function(){
	$(window).load(function(){
		AOS.init();
		responsiveSlick();

		cart();
		singleProduct();
		$( document.body ).on( 'updated_cart_totals', function(event){
			setTimeout(function(){
				cart();
			}, 200);
		});

		if( $('#nofication-modal').length > 0 ) {
			$('#nofication-modal').modal('show');
		}

		$(document).on('click', 'a[data-target="#login-modal"]', function(e){
			e.preventDefault();
			$('#register-modal').modal('hide');
		});
		$(document).on('click', 'button[data-target="#register-modal"]', function(e){
			e.preventDefault();
			$('#login-modal').modal('hide');
		});

		// MAGNIFIER
		var native_width = 0;
		var native_height = 0;

		$(".magnify").each(function(e){
			
			$(this).mouseover(function(){
				$(this).find(".large").fadeIn(100);
			}).mouseout(function(){
				$(this).find(".large").fadeOut(100);
			});

			$(this).mousemove(function(e){
				var $imageUrl = $(this).find(".small").attr("data-url");
				if(!native_width && !native_height){
				var image_object = new Image();
					image_object.src = $(this).find(".small").attr("data-url");
				native_width = image_object.width;
				native_height = image_object.height;
			}else{
				var magnify_offset = $(this).offset();
				var mx = e.pageX - magnify_offset.left;
				var my = e.pageY - magnify_offset.top;

				if($(".large").is(":visible")){
					var rx = Math.round(mx/$(this).find(".small").width()*native_width - $(this).find(".large").width()/2)*-1;
					var ry = Math.round(my/$(this).find(".small").height()*native_height - $(this).find(".large").height()/2)*-1;
					var bgp = rx + "px " + ry + "px";

					var px = mx - $(".large").width()/2;
					var py = my - $(".large").height()/2;

					$(this).find(".large").css({
									'left' : px,
									'top' : py,
								// 	'background': 'url(' + $imageUrl + ') no-repeat', 
								// 	'background-position' : bgp
								});
					$(this).find(".large .zoomImage").css({
														'left' : px,
														'top' : py,
														'background': 'url(' + $imageUrl + ') no-repeat', 
														'background-position' : bgp
													});
				}
			}
			});
		});

		// CAROUSEL
		$('#singProductCarousel').carousel({
			interval: 4000
		});
		
		// LIGHTCASE
		$('a[data-rel^=lightcase]').lightcase();
		// END LIGHT CASE

		// MASONRY
		$('.news-archive').isotope({
			itemSelector: '.news-item',
			masonry: {
				columnWidth: '.news-item',
				gutter: 22
			}
		});
		
		$('.featured-product-masonry').isotope({
			itemSelector: '.featured-item',
			masonry: {
				columnWidth: '.featured-item',
				gutter: 22
			}
		});

		// END MASONRY

		// PRODUCT CATEGORY
		$('ul.product-cat-listing li').each(function(){
			if($(this).children('ul.product-cat-child').length){
				$(this).addClass('parent-has-child');
			}
		});
		// END PRODUCT CATEGORY

		// ACCORDION
		$(document).on('click', '.accordion-link', function(){
			$('.page-layout .faq-layout .faq-wrapper .panel-group .panel .panel-heading a').removeClass('active-in');
			$(this).addClass('active-in');
		});

		// TESTIMONIALS LIST
		$('.testimonials-carousel').carouFredSel({
			// auto: false,
			responsive: true,
			width: '100%',
			scroll: 1,
			scroll: { 
					items:1,
					duration: 1000,
					pauseOnHover: 'immediate'  
					},
			// scroll : { fx : "crossfade" },
			mousewheel: true,
			swipe: {
			  onMouse: true,
			  onTouch: true
			},
			items: {

			  visible: {
			    min: 1,
			    max: 1
			  }

			}
		});
		// END TESTIMONIALS LIST
	});

	// CODES THAT INCLUDE ON LOAD AND RESIZE AT THE SAME TIME
	var resizeTimer;
	$(window).on('load resize', function(e){
		clearTimeout(resizeTimer);

		resizeTimer = setTimeout(function() {
			// Run code here, resizing has "stopped"

			/* MODERNIZR LAYOUT - This serves as the the media query inside the Javascript */
			if( Modernizr.mq('(min-width: 1200px)') ) {
				// CODES FOR LARGE DEVICES HERE

				// EQUALIZER
				var $maxHeight = 0;
				$('.equalizer').each(function(){
					var $contentHeight = $(this).find('.equalize').height();
					if( $contentHeight > $maxHeight ) {
						$maxHeight = $contentHeight;
					}
				});
				$('.equalize').height($maxHeight); 

				// SINGLE PRODUCT THUMB SLIDER
					var $sliderHeight = $('.single-product-page-layout .single-product-wrapper .carousel .carousel-inner').height();
					$('.single-product-page-layout .single-product-wrapper .carousel .carousel-indicators').css({ 'max-height' : $sliderHeight-4 });
				// END PRODUCT SLIDER
				
				// IMAGE LIST
				$('.carousel-image').carouFredSel({
					// auto: false,
					responsive: true,
					width: '100%',
					scroll: 1,
					// scroll : { fx : "crossfade" },
					mousewheel: true,
					swipe: {
					  onMouse: true,
					  onTouch: true
					},
					items: {
					  width: 210,
					  visible: {
					    min: 1,
					    max: 7
					  }
					}
				});
				// END IMAGE LIST


			}
			else if( Modernizr.mq('(min-width: 992px)') && Modernizr.mq('(max-width: 1199px)') ) {
				// CODES FOR MEDIUM DEVICES HERE

				// EQUALIZER
				var $maxHeight = 0;
				$('.equalizer').each(function(){
					var $contentHeight = $(this).find('.equalize').height();
					if( $contentHeight > $maxHeight ) {
						$maxHeight = $contentHeight;
					}
				});
				$('.equalize').height($maxHeight); 

				// IMAGE LIST
				$('.carousel-image').carouFredSel({
					// auto: false,
					responsive: true,
					width: '100%',
					scroll: 1,
					// scroll : { fx : "crossfade" },
					mousewheel: true,
					swipe: {
					  onMouse: true,
					  onTouch: true
					},
					items: {
					  width: 210,
					  visible: {
					    min: 1,
					    max: 4
					  }
					}
				});
				// END IMAGE LIST


			}
			else if( Modernizr.mq('(max-width: 991px)') && Modernizr.mq('(min-width: 768px)')){
				// CODES FOR SMALL DEVICES HERE

				// SINGLE PRODUCT THUMB SLIDER
					var $sliderWidth = $('.single-product-page-layout .single-product-wrapper .carousel .carousel-inner').width();
					var $sliderThumbHeight = $('.single-product-page-layout .single-product-wrapper .carousel .carousel-indicators li').height();
					$('.single-product-page-layout .single-product-wrapper .carousel .carousel-indicators').css({ 'width' : $sliderWidth-4, 'max-height' : $sliderHeight+4});
				// END PRODUCT SLIDER
				// IMAGE LIST
				$('.carousel-image').carouFredSel({
					// auto: false,
					responsive: true,
					width: '100%',
					scroll: 1,
					// scroll : { fx : "crossfade" },
					mousewheel: true,
					swipe: {
					  onMouse: true,
					  onTouch: true
					},
					items: {
					  width: 210,
					  visible: {
					    min: 1,
					    max: 3
					  }
					}
				});
				// END IMAGE LIST
			}
			else{
				// CODES FOR EXTRA SMALL DEVICES HERE

				// SINGLE PRODUCT THUMB SLIDER
					var $sliderWidth = $('.single-product-page-layout .single-product-wrapper .carousel .carousel-inner').width();
					var $sliderThumbHeight = $('.single-product-page-layout .single-product-wrapper .carousel .carousel-indicators li').height();
					$('.single-product-page-layout .single-product-wrapper .carousel .carousel-indicators').css({ 'width' : $sliderWidth-4, 'max-height' : $sliderHeight+10});
				// END PRODUCT SLIDER

				// TESTIMONIALS LIST
				$('.testimonials-carousel').carouFredSel({
					// auto: false,
					height: 225,
					responsive: true,
					width: '100%',
					scroll: { 
							items:1,
							duration: 1000
						},
					// scroll : { fx : "crossfade" },
					mousewheel: true,
					swipe: {
					  onMouse: true,
					  onTouch: true
					},
					items: {
					  visible: {
					    min: 1,
					    max: 1
					  }
					}
				});

				// IMAGE LIST
				$('.carousel-image').carouFredSel({
					// auto: false,
					responsive: true,
					width: '100%',
					scroll: 1,
					// scroll : { fx : "crossfade" },
					mousewheel: true,
					swipe: {
					  onMouse: true,
					  onTouch: true
					},
					items: {
					  width: 210,
					  visible: {
					    min: 1,
					    max: 1
					  }
					}
				});
				// END IMAGE LIST
			}

		}, 250);

	});
});

// <div class="slick-responsive" data-breakpoints="1199,4|991,2|659,2|480,1" data-thumb=".sample" data-arrows="true" data-defaultview="4">
function responsiveSlick(){
	$('.slick-responsive').each(function(){
		var breaks = $(this).data('breakpoints').split('|'),
		breakArr = [],
		arrowsval;
		$.each(breaks, function(i, items){
			breakArr.push({
				breakpoint: parseInt(items.split(',')[0]),
	      settings: {
	        slidesToShow: parseInt(items.split(',')[1])
	      }
			});
		});
		if( $(this).data('arrows') ) {
			arrowsval = true;
		} else {
			arrowsval = false;
		}
		$(this).slick({
		  dots: false,
		  infinite: true,
		  speed: 300,
		  autoplay: true,
		  arrows: arrowsval,
		  slidesToShow: $(this).data('defaultview'),
		  slidesToScroll: 1,
		  adaptiveHeight: true,
		  asNavFor: $(this).data('thumb'),
		  responsive : breakArr,
		  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
		});
	});
}

function cart(){
	$('.cart-item').each(function(){
		var parent = $(this);
		parent.find('.minus-qty').on('click', function(){
			var value = parseInt(parent.find('.quantity > input').val());
			if( value > 1 ) {
				parent.find('.quantity > input').val( value-1 );
			} else {
				parent.find('.quantity > input').val(1);
			}
			$('.cart .action button[name="update_cart"]').removeAttr('disabled');
		});
		parent.find('.add-qty').on('click', function(){
			var value = parseInt(parent.find('.quantity > input').val());
			parent.find('.quantity > input').val( value+1 );
			$('.cart .action button[name="update_cart"]').removeAttr('disabled');
		});
	});
}

function singleProduct(){
	$('.product .quantity').each(function(){
		var parent = $(this);
		$(this).find('.qty').wrap('<div class="qty-wrapper"></div>');
		$('<button type="button" class="qty-control minus">-</button>').insertBefore('.qty-wrapper input');
		$('<button type="button" class="qty-control add">+</button>').insertAfter('.qty-wrapper input');

		parent.find('.qty-control.minus').on('click', function(){
			var value = parseInt(parent.find('.qty-wrapper > input').val());
			if( value > 1 ) {
				parent.find('.qty-wrapper > input').val( value-1 );
			} else {
				parent.find('.qty-wrapper > input').val(1);
			}
		});

		parent.find('.qty-control.add').on('click', function(){
			var value = parseInt(parent.find('.qty-wrapper > input').val());
			parent.find('.qty-wrapper > input').val( value+1 );
		});

	});
}